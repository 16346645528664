import { FC } from 'react';
import { Position } from '@turf/turf';

import {
  CustomMarker,
  CustomMarkerProps,
} from 'components/Map/FeatureTypes/CustomMarker';
import { getPixelValueByMeters, getZoomPercent } from 'utils';
import { convertHexToRGBA } from 'utils/helpers/color';

interface ZoomBasedPointProps extends CustomMarkerProps {
  zoom: number;
  color?: string;
  isActive?: boolean;
}

interface PointProps {
  zoom: number;
  coordinates: Position;
  color?: string;
  isActive?: boolean;
}

const PinPoint: FC<Pick<PointProps, 'color' | 'isActive'>> = ({
  color,
  isActive,
}) => {
  const outlineColor = isActive ? '#053C92' : '#E3E6F1';

  return (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ color }}
    >
      <path
        d="M15.2826 29.9551C15.4439 29.7903 15.6065 29.6246 15.7701 29.4578C17.9252 27.2608 20.2585 24.8822 22.0922 22.2941C24.0681 19.5052 25.5 16.4251 25.5 13C25.5 8.88175 24.1132 5.74053 21.824 3.63222C19.5416 1.53011 16.4115 0.5 13 0.5C9.58856 0.5 6.45847 1.53011 4.17602 3.63222C1.88685 5.74053 0.50003 8.88175 0.50003 13C0.50003 16.4251 1.93195 19.5052 3.9079 22.2941C5.74157 24.8822 8.07485 27.2608 10.23 29.4578C10.3936 29.6246 10.5562 29.7904 10.7174 29.9551C11.1534 30.4003 11.5595 30.7703 11.9167 31.0327C12.0953 31.1638 12.2718 31.2757 12.4418 31.3564C12.6063 31.4345 12.7988 31.5 13 31.5C13.2013 31.5 13.3938 31.4345 13.5582 31.3564C13.7283 31.2757 13.9048 31.1638 14.0834 31.0327C14.4406 30.7703 14.8467 30.4003 15.2826 29.9551Z"
        fill="currentColor"
        stroke={outlineColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 19.25C16.4518 19.25 19.25 16.4518 19.25 13C19.25 9.54822 16.4518 6.75 13 6.75C9.54822 6.75 6.75 9.54822 6.75 13C6.75 16.4518 9.54822 19.25 13 19.25ZM13 20.5C17.1421 20.5 20.5 17.1421 20.5 13C20.5 8.85786 17.1421 5.5 13 5.5C8.85786 5.5 5.5 8.85786 5.5 13C5.5 17.1421 8.85786 20.5 13 20.5Z"
        fill="#E3E6F1"
      />
    </svg>
  );
};

const IconPoint: FC<Pick<PointProps, 'color' | 'isActive'>> = ({
  color,
  isActive,
}) => {
  const outlineColor = isActive ? '#053C92' : '#E3E6F1';

  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      style={{ color }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13"
        cy="13"
        r="12.5"
        fill="currentColor"
        stroke={outlineColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 19.25C16.4518 19.25 19.25 16.4518 19.25 13C19.25 9.54822 16.4518 6.75 13 6.75C9.54822 6.75 6.75 9.54822 6.75 13C6.75 16.4518 9.54822 19.25 13 19.25ZM13 20.5C17.1421 20.5 20.5 17.1421 20.5 13C20.5 8.85786 17.1421 5.5 13 5.5C8.85786 5.5 5.5 8.85786 5.5 13C5.5 17.1421 8.85786 20.5 13 20.5Z"
        fill="#E3E6F1"
      />
    </svg>
  );
};

const TransparentPoint: FC<Omit<PointProps, 'zoomPercent'>> = ({
  zoom,
  coordinates,
  color,
  isActive,
}) => {
  const size = getPixelValueByMeters(15, coordinates[0], zoom);
  const outlineColor = isActive ? '#053C92' : color;
  const backgroundColor = convertHexToRGBA(color ?? '#000000', 40);

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: size,
        borderColor: outlineColor,
        backgroundColor: backgroundColor,
      }}
      className="border-[3px] border-solid"
    />
  );
};

// TODO: temporary static point. Replace with map symbol after actual icon come from BE
const Point: FC<PointProps> = ({ zoom, coordinates, color, isActive }) => {
  const zoomPercent = getZoomPercent(zoom);

  if (zoomPercent < 50) {
    return <IconPoint color={color} isActive={isActive} />;
  }

  if (zoomPercent < 80) {
    return <PinPoint color={color} isActive={isActive} />;
  }

  return (
    <TransparentPoint
      zoom={zoom}
      coordinates={coordinates}
      color={color}
      isActive={isActive}
    />
  );
};

export const ZoomBasedPoint: FC<ZoomBasedPointProps> = ({
  coordinates,
  zoom,
  color,
  isActive,
  ...props
}) => (
  <CustomMarker coordinates={coordinates} {...props}>
    <Point
      zoom={zoom}
      coordinates={coordinates}
      color={color}
      isActive={isActive}
    />
  </CustomMarker>
);
