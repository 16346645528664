import { useControl } from 'react-map-gl';
import MapboxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder';
import { MAP_BOX_TOKEN } from 'constants/map';
import { FeatureCollection } from 'geojson';
import mapboxgl from 'mapbox-gl';

import { getCoordinatesFromString } from 'utils/converterUtils';

import { convertCoordinates } from '../../../api/converter';
import { useAppDispatch } from '../../../hooks';
import { mapActions } from '../../../store';
import { getCoordinatesFromDecimalString } from '../../../utils';
import { isNumber } from '../../../utils/validations/number';

const DEFAULT_SK42_HEIGHT = 162;

const prepareResult = (
  lat: number,
  lon: number,
  query: string,
  placeName: string
): Result => ({
  address: '',
  bbox: [lon, lat, lon, lat],
  center: [lon, lat],
  context: [],
  geometry: {
    type: 'Point',
    coordinates: [lon, lat],
  },
  place_name: placeName,
  place_type: ['place'],
  properties: { isCoords: true },
  relevance: 0,
  text: query,
  type: 'Feature',
});

type CoordsProcessorT = (
  coords: [number, number],
  query: string
) => Promise<Result[]>;

const maybeGetCoords = async (
  query: string,
  coordsProcessorFunc: CoordsProcessorT
) => {
  const maybeCoords = getCoordinatesFromDecimalString(query);
  if (maybeCoords) {
    const [lat, lon] = maybeCoords;
    if (isNumber(lat) && isNumber(lon)) {
      return await coordsProcessorFunc([lat, lon], query);
    }
  }
  return [];
};

const prepareWgsResult: CoordsProcessorT = async (coords, query) => [
  prepareResult(coords[0], coords[1], query, `Перейти к WGS координате`),
];

const prepareSk42Result: CoordsProcessorT = async (coords, query) => {
  const resultWgs = await convertCoordinates('sk42', 'wgs', {
    x: coords[0],
    y: coords[1],
    h: DEFAULT_SK42_HEIGHT,
  }).catch(() => null);
  if (resultWgs && resultWgs.to === 'wgs') {
    return [
      prepareResult(
        resultWgs.payload.b,
        resultWgs.payload.l,
        query,
        `Перейти к СК-42 координате`
      ),
    ];
  }
  return [];
};

const preparePossibleSk42Query = (query: string): string =>
  query.replace('X:', '').replace('Y:', '');

const asyncLocalGeocoder = async (query: string) => {
  if (query.includes('X')) {
    return [
      ...(await maybeGetCoords(
        preparePossibleSk42Query(query),
        prepareSk42Result
      )),
    ] as unknown as FeatureCollection;
  }
  return [
    ...(await maybeGetCoords(query, prepareWgsResult)),
  ] as unknown as FeatureCollection;
};
// ^^ dirty trick, however it fails if this function returns actual FeatureCollection

export const useGeocoder = () => {
  const dispatch = useAppDispatch();

  useControl(
    () => {
      const geocoder = new MapboxGeocoder({
        accessToken: MAP_BOX_TOKEN,
        marker: false,
        mapboxgl,
        language: 'ru-RU',
        collapsed: false,
        placeholder: 'Начните поиск',
        flyTo: false,
        enableEventLogging: false,
        getItemValue: (item) => {
          if (item.properties?.isCoords) {
            setTimeout(() => geocoder.setInput(''), 200);
            return item.text;
          } else {
            return item.place_name;
          }
        },
        externalGeocoder: asyncLocalGeocoder,
      });
      geocoder.on('error', () => null);
      geocoder.on('result', (e) => {
        const coordinates = getCoordinatesFromString(
          String(e.result.text)
            .replaceAll(/"|(``)/g, '″')
            .replaceAll(/'|`/g, '′') ?? ''
        )?.reverse();

        const getSafeGeocoderResult = () => {
          const isConvertedCoordinates =
            coordinates && coordinates[0] && coordinates[1];
          return isConvertedCoordinates ? coordinates : e.result.center;
        };

        geocoder.clear();
        dispatch(mapActions.setGeocoderResult(getSafeGeocoderResult()));
      });
      // ^^^ is used to handle mapbox 422 response causing runtime error
      return geocoder;
    },
    {
      position: 'bottom-left',
    }
  );
};
